<template>
  <v-container fluid grid-list-lg>
    <v-card :loading="loading" outlined>
      <ListTitle
        :showBackButton="true"
        :access="false"
        title="Deleted Invoice"
      />
      <v-card-title>
        <v-flex xs12 sm4>
          <v-text-field
            v-model="search"
            outlined
            prepend-inner-icon="search"
            rounded
            dense
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
        <!-- <v-flex shrink v-if="showAll">
          <v-btn rounded color="success">text</v-btn>
        </v-flex> -->
        <DateTimePicker
          defaultRange="since30DaysAgo"
          :range="true"
          v-model="dateTime"
          @submit="getList()"
        />
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="Invoices"
          :search="search"
          :loading="loading"
          :sort-desc="sortDesc"
          :sort-by="sortBy"
          :page.sync="page"
          :items-per-page="itemsPerPage"
        >
          <template v-slot:item="{ item }">
            <tr class="pointer">
              <td class="text-left" @click="viewInvoice(item)">
                {{ item.date | moment("MM/DD/YYYY") }}
              </td>
              <td class="text-left" @click="viewInvoice(item)">
                {{ item.deletedAt | moment("MM/DD/YYYY") }}
              </td>
              <td class="text-left" @click="viewInvoice(item)">
                {{ item.localId }}
              </td>
              <td class="text-left" @click="viewInvoice(item)">
                {{ item.Customer.name }}
              </td>

              <td class="text-right" @click="viewInvoice(item)">
                {{ item.total | currency }}
              </td>
              <td class="text-right" @click="viewInvoice(item)">
                {{ item.due | currency }}
              </td>
              <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-divider></v-divider>
                    <v-list-item @click="viewInvoice(item)">
                      <v-list-item-title>View Invoice</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="viewPackingSlip(item)">
                      <v-list-item-title>View Packing Slip</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <v-alert slot="no-results" :value="true" color="error" icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import invoiceService from "../service";
// import InvoiceViewLink from "./InvoiceViewLink";
const DateTimePicker = () => import("@/components/DateTimePicker");
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  name: "invoice-list",
  data() {
    return {
      fab: false,
      dateTime: {
        start: this.$moment().startOf("day").toISOString(),
        end: this.$moment().endOf("day").toISOString(),
      },
      createDialog: false,
      selectedPayee: null,
      headers: [],
      Invoices: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "localId",
      pageCount: 0,
      sortDesc: true,
      itemsPerPage: 10,
    };
  },
  created() {
    this.getList();
    this.loadHeader();
    this.$events.listen("invoiceListEvent", () => this.getList());
    document.title = "smplsale - Invoice List";
  },
  beforeDestroy() {
    this.$events.remove("invoiceListEvent");
  },
  components: {
    DateTimePicker,
    // InvoiceViewLink
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  methods: {
    viewProfile(val) {
      this.$router.push({ path: `/customer/${val.CustomerId}/profile` });
    },
    viewInvoice(invoice) {
      const hashId = hashids.encode(invoice.id);
      const createdLink = `/global/invoice/deleted/${hashId}/view`
      // console.log('createdLink', createdLink)
      const routeData = this.$router.resolve({
        path: createdLink,
      });
      // this.$router.push({ path: `/global/invoice/deleted/${hashId}/view` });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    viewPackingSlip(invoice) {
      const hashId = hashids.encode(invoice.id);
      const routeData = this.$router.resolve({
        path: `/global/invoice/deleted/${hashId}/packingSlip`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    loadHeader() {
      this.headers = [];
      this.headers.push({
        text: this.$t("labels.date"),
        align: "left",
        value: "date",
        sortable: true,
      });

      this.headers.push({
        text: "Deleted At",
        align: "left",
        value: "deletedAt",
        sortable: true,
      });

      this.headers.push({
        text: "Invoice Number",
        align: "left",
        value: "localId",
        sortable: true,
      });

      this.headers.push({
        text: "Customer Name",
        value: "Customer.name",
        align: "left",
        sortable: true,
      });

      // this.headers.push({
      //   text: "Note",
      //   align: "left",
      //   value: "note",
      //   sortable: true
      // });

      this.headers.push({
        text: "Total",
        value: "total",
        align: "right",
        sortable: true,
      });

      // this.headers.push({
      //   text: "Payment",
      //   value: "payment",
      //   align: "right",
      //   sortable: true
      // });
      this.headers.push({
        text: "Balance",
        value: "due",
        align: "right",
        sortable: true,
      });
      this.headers.push({
        text: "",
        value: "action",
        sortable: false,
      });
    },
    getList() {
      this.loading = true;
      let where = {
        start: this.dateTime.start,
        end: this.dateTime.end,
      };
      where.deleted = true;
      // if (this.CustomerId) {
      //   where.CustomerId = this.CustomerId;
      // }

      // if (this.due) {
      //   where.due = true;
      // }

      return invoiceService.getAll(where).then((response) => {
        // // console.log("invoice", response);
        this.loading = false;
        this.Invoices = response.data;
        return response;
      });
    },
    editInvoice(invoice) {
      // // console.log('ddf', invoice)
      this.$router.push({ path: `/customer/invoice/${invoice.id}/edit` });
    },
    deleteInvoice(invoice) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          invoiceService
            .delete(invoice.id)
            .then((response) => {
              if (response.data) {
                this.$swal("Deleted!", "Invoice has been deleted.", "success");
                this.getList();
                this.$events.emit("customerProfileEvent");
              }
            })
            .catch((error) => {
              this.$swal({
                title: "Delete Invoice",
                text: error.data.message,
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>
