var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","loading":_vm.loading}},[(_vm.Invoice)?_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-spacer'),_c('v-flex',{attrs:{"xs12":"","sm4":""}},[_c('v-text-field',{attrs:{"outlined":"","prepend-inner-icon":"search","rounded":"","dense":"","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"hide-default-footer":"","itemsPerPage":500,"no-data-text":"No item on this invoice yet","items":_vm.Invoice.InvoiceDetails},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.imeiNumber)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.qty)+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.price))+" ")]),_c('td',{staticClass:"text-right"},[_c('span',{style:({
                color:
                  parseFloat(item.price) > parseFloat(item.cost)
                    ? 'green'
                    : 'red',
              })},[_vm._v(_vm._s(_vm._f("currency")((item.qty * item.price))))])]),_c('td',{staticClass:"text-right"},[_c('v-text-field',{staticClass:"al-r",attrs:{"outlined":"","min":0,"prepend-inner-icon":"mdi-clipboard-check-outline","hide-details":"","dense":""},on:{"click:prepend-inner":function($event){return _vm.copyPrice(item, index)},"focus":function($event){return $event.target.select()}},model:{value:(item.cost),callback:function ($$v) {_vm.$set(item, "cost", $$v)},expression:"item.cost"}})],1)])]}}],null,false,468215878)}),_c('v-divider')],1):_vm._e(),_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-row',{staticClass:"px-3",attrs:{"align":"center"}},[_c('v-col',{staticClass:"subtitle-1"},[_c('div',{staticClass:"text-left"},[_c('span',[_vm._v(_vm._s(_vm.totalQty))]),_vm._v(" Total Item ")])]),_c('v-spacer'),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{staticClass:"text-right",attrs:{"loading":_vm.loading,"color":"pink","dark":""},on:{"click":_vm.saveInvoice}},[_vm._v("update")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }