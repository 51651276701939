<template>
  <v-card outlined :loading="loading">
    <v-card-text v-if="Invoice">
      <v-layout row wrap>
        <v-spacer></v-spacer>
        <v-flex xs12 sm4>
          <v-text-field
            v-model="search"
            outlined
            prepend-inner-icon="search"
            rounded
            dense
            label="Search"
            single-line
            hide-details
            clearable
          >
          </v-text-field>
        </v-flex>
      </v-layout>

      <v-data-table
        :search="search"
        :headers="headers"
        hide-default-footer
        :itemsPerPage="500"
        no-data-text="No item on this invoice yet"
        :items="Invoice.InvoiceDetails"
      >
        <template v-slot:item="{ item, index }">
          <tr>
            <td>
              {{ item.name }}
            </td>
            <td class="text-center">
              {{ item.imeiNumber }}
            </td>
            <td class="text-center">
              {{ item.qty }}
            </td>
            <td class="text-right">
              {{ item.price | currency }}
            </td>
            <td class="text-right">
              <span
                :style="{
                  color:
                    parseFloat(item.price) > parseFloat(item.cost)
                      ? 'green'
                      : 'red',
                }"
                >{{ (item.qty * item.price) | currency }}</span
              >
            </td>
            <td class="text-right">
              <v-text-field
                v-model="item.cost"
                outlined
                :min="0"
                prepend-inner-icon="mdi-clipboard-check-outline"
                class="al-r"
                @click:prepend-inner="copyPrice(item, index)"
                hide-details
                @focus="$event.target.select()"
                dense
              ></v-text-field>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider></v-divider>
    </v-card-text>

    <v-card outlined tile>
      <v-row class="px-3" align="center">
        <v-col class="subtitle-1">
          <div class="text-left">
            <span>{{ totalQty }}</span> Total Item
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="shrink">
          <v-btn
            class="text-right"
            @click="saveInvoice"
            :loading="loading"
            color="pink"
            dark
            >update</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import invoiceService from "../service";
export default {
  props: ["InvoiceId"],
  data() {
    return {
      search: null,
      Invoice: null,
      loading: true,
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Serial Number",
          align: "left",
          value: "serialNumber",
          sortable: true,
        },
        {
          text: "Quantity",
          align: "center",
          value: "qty",
          width: "100",
          sortable: true,
        },
        {
          text: "Price",
          align: "right",
          value: "price",
          sortable: true,
        },
        {
          text: "Total",
          align: "right",
          value: "total",
          sortable: true,
        },
        {
          text: "Each Cost",
          align: "right",
          value: "cost",
          sortable: true,
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    InvoiceId() {
      this.loadData();
    },
  },
  computed: {
    totalQty() {
      let total = 0;
      if (this.Invoice && this.Invoice.InvoiceDetails) {
        this.Invoice.InvoiceDetails.map((row) => {
          total += parseFloat(row.qty);
        });
      }
      return total;
    },
  },
  methods: {
    saveInvoice() {
      // console.log("invoice", this.Invoice.InvoiceDetails);
      if (this.Invoice.InvoiceDetails) {
        this.loading = true;
        return invoiceService
          .adjustCost(this.Invoice.id, this.Invoice)
          .then(() => {
            this.loadData();
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "Invoice was Updated!",
              });
            this.loading = false;
          })
          .catch((err) => {
            // console.log("error", err);
            this.loading = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: err.data.message,
              });
          });
      }
    },
    async CopyPriceToAll(index) {
      const sourcePriceRow = this.Invoice.InvoiceDetails[index];
      await Promise.all(
        this.Invoice.InvoiceDetails.map((row) => {
          // // console.log('change price', row.ProductId,  sourcePriceRow.ProductId)
          if (row.ProductId === sourcePriceRow.ProductId) {
            row.cost = sourcePriceRow.cost;
          }
        })
      );
    },
    copyPrice(item, index) {
      this.$swal({
        title: "Change Cost Price To All",
        text: `You want to change all ${item.name} price to ${item.cost}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.CopyPriceToAll(index);
        }
      });
    },
    loadData() {
      this.loading = true;
      return invoiceService.getById(this.InvoiceId).then((response) => {
        this.$Progress.finish();
        this.Invoice = response.data;
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped></style>
