var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[(_vm.InvoiceDetails.length > 0)?_c('v-flex',{attrs:{"shrink":""}},[_c('v-btn-toggle',{attrs:{"color":"deep-purple accent-3","dense":"","group":""},model:{value:(_vm.showCost),callback:function ($$v) {_vm.showCost=$$v},expression:"showCost"}},[_c('v-btn',{staticClass:"ma-0",attrs:{"value":1,"text":"","outlined":""}},[_c('v-icon',[_vm._v("mdi-currency-usd")])],1)],1)],1):_vm._e(),(_vm.currentBusiness && _vm.currentBusiness.metadata && _vm.currentBusiness.metadata.serializeProduct)?_c('v-flex',{attrs:{"shrink":""}},[_c('ReturnPhone',{on:{"submit":_vm.insertReturn}})],1):_vm._e(),_c('v-flex',[(_vm.totalQty !== 0)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"indigo lighten-2","text-color":"white"}},[_c('v-avatar',{staticClass:"mr-2",attrs:{"left":"","color":"indigo "}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-cart-outline")])],1),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.totalQty))])],1):_vm._e()],1),(_vm.replaceQty !== 0)?_c('v-flex',{attrs:{"shrink":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"color":"yellow lighten-2","text-color":"black"}},'v-chip',attrs,false),on),[_c('v-avatar',{staticClass:"mr-2",attrs:{"left":"","color":"yellow"}},[_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-barcode-scan")])],1),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.replaceQty))])],1)]}}],null,false,3142838911)},[_c('span',[_vm._v("Serial Item need to be replace")])])],1):_vm._e(),_c('v-spacer')],1),_c('v-data-table',{attrs:{"id":"invoice-cart","search":_vm.search,"headers":_vm.headers,"hide-default-footer":"","itemsPerPage":1000,"no-data-text":"No item on this invoice yet","items":_vm.InvoiceDetails},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',{style:({ 'background-color': _vm.activeColor(item) })},[_c('td',[_c('v-layout',{attrs:{"align-center":"","justify-start":"","row":"","fill-height":"","wrap":""}},[(!item.delete)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(index)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("delete")])],1):_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.unDeleteItem(index)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-delete-empty")])],1),(!item.delete)?_c('v-text-field',{attrs:{"disabled":_vm.lockProductName,"outlined":"","hide-details":"","dense":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}):_c('span',{class:{ deleted: item.delete }},[_vm._v(_vm._s(item.name))])],1)],1),(_vm.currentBusiness && _vm.currentBusiness.metadata && _vm.currentBusiness.metadata.serializeProduct)?_c('td',{staticClass:"text-left"},[(
              item.Product &&
              item.Product.trackSerialNumber &&
              item.type === 'order'
            )?_c('div',[_c('v-chip',{staticClass:"ma-2",attrs:{"width":"100%","label":"","color":"yellow"}},[_vm._v(" Replace With Serial Numbers & Delete It ")])],1):_c('div',[(!item.delete)?_c('v-text-field',{attrs:{"outlined":"","disabled":"","hide-details":"","dense":""},model:{value:(item.imeiNumber),callback:function ($$v) {_vm.$set(item, "imeiNumber", $$v)},expression:"item.imeiNumber"}}):_c('span',{class:{ deleted: item.delete }},[_vm._v(_vm._s(item.imeiNumber))])],1)]):_vm._e(),_c('td',{staticClass:"text-center"},[(!item.delete)?_c('v-text-field',{staticClass:"centered-input",attrs:{"disabled":item.imeiNumber ? true : false,"outlined":"","type":"number","hide-details":"","dense":""},on:{"focus":function($event){return $event.target.select()},"blur":function($event){return _vm.checkPrice(item)}},model:{value:(item.qty),callback:function ($$v) {_vm.$set(item, "qty", $$v)},expression:"item.qty"}}):_c('span',{class:{ deleted: item.delete }},[_vm._v(_vm._s(item.qty))])],1),(_vm.currentBusiness && _vm.currentBusiness.metadata && _vm.currentBusiness.metadata.boxCountOnInventory)?_c('td',[_c('div',{staticClass:"text-center",class:{ deleted: item.delete }},[_vm._v(_vm._s(item.unitCountInBox))])]):_vm._e(),_c('td',{staticClass:"text-right"},[(!item.delete)?_c('v-text-field',{staticClass:"al-r",attrs:{"outlined":"","min":0,"prepend-inner-icon":"mdi-clipboard-check-outline","type":"number","hide-details":"","dense":""},on:{"click:prepend-inner":function($event){return _vm.copyPrice(item, index)},"blur":function($event){return _vm.checkPrice(item)},"focus":function($event){return $event.target.select()}},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}}):_c('span',{class:{ deleted: item.delete }},[_vm._v(_vm._s(_vm._f("currency")(item.price)))])],1),_c('td',{staticClass:"text-right"},[_c('span',{class:{ deleted: item.delete },style:({
              color:
                parseFloat(item.price) > parseFloat(item.cost)
                  ? 'green'
                  : 'red',
            })},[_vm._v(_vm._s(_vm._f("currency")((item.total))))]),(_vm.showCost)?_c('span',[(_vm.checkRightStatus(42))?_c('span',[(_vm.showCost)?_c('v-text-field',{staticClass:"al-r",attrs:{"outlined":"","hide-details":"","dense":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(item.cost),callback:function ($$v) {_vm.$set(item, "cost", $$v)},expression:"item.cost"}}):_vm._e()],1):_c('span',[_vm._v("/"+_vm._s(_vm._f("currency")(item.cost)))])]):_vm._e()])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }