<template>
  <v-card outlined>
    <v-card-text>
      <v-layout row :wrap="$vuetify.breakpoint.xsOnly" align-center justify-space-between fill-height>
        <v-flex xs12 sm3>
          <!-- selectedProduct: {{ selectedProduct }} -->
          <AutoComplete @submit="productSubmitted" ref="selectedProductChild" @emptyEntryBar="emptyEntryBar()" />
          <!-- <v-autocomplete v-model="selectedProduct" hide-no-data ref="productSearch" @keyup.enter="searchIMEI" outlined
            :loading="productSearchLoading" prepend-inner-icon="search" rounded allow-overflow dense auto-select-first
            single-line :error-messages="error" :hide-details="error.length === 0" clearable eager id="productSearch"
            :items="Products" item-text="name" return-object :search-input.sync="phoneSearch"
            :label="$t('labels.searchProduct')">
            <template v-slot:prepend-item>
              <v-list-item ripple @click="openMultipleProductsDialog">
                <v-list-item-content>
                  <v-list-item-title>Choose Multiple Items</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mb-1"></v-divider>
            </template>
<template slot="item" slot-scope="{ item }">
              <div>
                <div>{{ item.name }}</div>
                <small v-if="item.dynamicPrice === item.price" class="red--text">{{ item.dynamicPrice | currency }}
                  *</small>
                <small v-else>{{ item.price | currency }}</small>
              </div>
              <v-spacer></v-spacer>
              <v-chip v-if="item.trackInventory" :color="item.trackSerialNumber
                ? 'light-blue darken-1'
                : 'teal darken-1'
                " dark>
                <v-avatar left class="light-blue darken-2" v-if="item.trackSerialNumber">
                  <v-icon small>mdi-barcode-scan</v-icon>
                </v-avatar>
                <v-avatar left v-else class="teal darken-3">
                  <v-icon small>mdi-package-variant-closed</v-icon>
                </v-avatar>
                <span>{{ item.balance }}</span>
              </v-chip>
            </template>
</v-autocomplete> -->
        </v-flex>
        <v-flex xs12 sm3>
          <v-text-field label="Item Name" @keyup.enter="$refs.qty.focus" v-model="name" outlined dense hide-details
            autocomplete ref="itemName" :disabled="lockProductName"></v-text-field>
        </v-flex>
        <!-- <v-flex sm1 v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">
          <v-text-field class="centered-input" @focus="$event.target.select()" ref="boxQty" :disabled="disabledQty"
            label="Box Qty" outlined dense type="number" hide-details @keyup.enter="$refs.qty.focus"
            v-model="boxQty"></v-text-field>
        </v-flex> -->
        <v-flex xs12 sm1>
          <v-text-field class="centered-input" @focus="$event.target.select()" ref="qty" :disabled="disabledQty"
            label="Qty" outlined dense type="number" hide-details @keyup.enter="$refs.price.focus"
            v-model="qty"></v-text-field>
        </v-flex>
        <v-flex xs12 sm2>
          <v-text-field class="right-input" @keyup.enter="submit" ref="price" @focus="$event.target.select()"
            label="Price" v-model="price" outlined type="number" dense hide-details></v-text-field>
        </v-flex>
        <v-flex sm2>
          <v-text-field class="right-input" ref="extAmount" disabled outlined dense hide-details
            @focus="$event.target.select()" v-model="total" label="Ext Amount"></v-text-field>
        </v-flex>
        <v-flex d-flex xs12 sm1>
          <v-btn color="info" ref="addbutton" large @click="submit">
            add
          </v-btn>
        </v-flex>
      </v-layout>
      <!-- v-if="selectedProduct && selectedProduct.boxPrice > 0"  The box contains 6 units priced at $120.00 in total, with a unit price of $20.00. -->
      <div
        v-if="selectedProduct && currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory"
        class="text-center">The box contains <span class="font-weight-bold">{{ selectedProduct.unitCountInBox }}</span>
        Units priced at <span class="font-weight-bold">{{ selectedProduct.price | currency }}</span> in total, with a
        unit
        price of <span class="font-weight-bold">{{
          selectedProduct.price / selectedProduct.unitCountInBox | currency }}</span> </div>
    </v-card-text>
    <v-dialog persistent v-model="dialog" max-width="650">
      <v-card :loading="loadingNumber">
        <ModelTitle title="Available Serial Numbers" @close="closeDialog()" />

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="search" outlined class="mb-2" prepend-inner-icon="search" rounded dense
                label="Search" single-line hide-details clearable></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-data-table v-model="selected" :headers="headers" :items="serailList" flat :search="search"
                item-key="id" show-select>
                <template v-slot:item.cost="{ item }">
                  {{ item.cost | currency }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="saveSerialNumbers"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openWeightDialog" persistent max-width="400">
      <v-card>
        <ModelTitle title="Weight Required" @close="closeWeight()" />
        <!-- <v-card-title class="headline">Weight Required</v-card-title> -->
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field class="centered-input" @focus="$event.target.select()" ref="weightQty" label="Quantity"
                outlined dense type="number" hide-details @keyup.enter="$refs.weight.focus" v-model="weightQty">
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field class="centered-input" @focus="$event.target.select()" ref="weight" label="Total Weight"
                outlined dense type="number" hide-details @keyup.enter="$refs.weightboxPrice.focus" v-model="weight">
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field class="right-input" @keyup.enter="submit" ref="weightboxPrice"
                @focus="$event.target.select()" label="Price" v-model="weightboxPrice" outlined type="number" dense
                hide-details></v-text-field>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-text>
          <div class="text-center font-weight-medium">Total: <span class="font-weight-medium text--black"> {{ weight *
            weightboxPrice | currency
              }}</span> <span>Each Box: <span>{{ (weight * weightboxPrice) / weightQty | currency }}</span></span>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeWeight()">Close</v-btn>
          <v-btn color="blue darken-1" dark @click="weightSubmit()">Save</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showErrorMessageDialog" persistent max-width="320">
      <v-card>
        <v-card-title class="headline"> Already Sold </v-card-title>
        <v-card-text>
          <div class="text-center">
            <div>
              <strong>{{ showErrorMessage }}</strong> if find this is a mistake
              click restock button to sale it
            </div>
            <div class="mt-2">
              <v-btn @click="restock" color="success">Restock</v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="showErrorMessageDialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import productService from "@/modules/Product/service";
import purchaseService from "@/modules/Inventory/Purchase/service";
import customerPriceService from "@/modules/Customer/CustomerPrice/service.js";
import AutoComplete from "@/modules/Product/components/AutoComplete.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      weightQty: null,
      weight: null,
      weightboxPrice: 0,
      showErrorMessageDialog: false,
      showErrorMessage: null,
      search: null,

      serailList: [],
      PriceList: [],
      openWeightDialog: false,
      headers: [
        {
          text: "SERIAL NUMBER",
          align: "start",
          sortable: true,
          value: "imeiNumber",
        },
        {
          text: "COST",
          align: "end",
          sortable: true,
          value: "cost",
        },
      ],
      loadingNumber: false,

      selectedProduct: null,
      selected: [],
      name: null,
      disabledQty: false,
      boxQty: 1,
      qty: 1,
      price: 0,
      total: 0,
      dialog: false,
    };
  },
  components: {
    AutoComplete
  },
  created() {
    this.emptyEntryBar();
    this.getCustomerPriceList();
    this.$events.listen("customerChange", () => this.getCustomerPriceList());
  },
  beforeDestroy() {
    this.$events.remove("customerChange");
  },
  computed: {
    ...mapGetters("invoice", ["Invoice", "InvoiceDetails"]),
    ...mapGetters("global", ["currentUser", "checkRightStatus", "currentBusiness"]),
    // priceLabel() {
    //   let label = 'Price'
    //   if (this.currentBusiness?.metadata?.boxCountOnInventory ) {
    //     label = 'Price'
    //   }
    //   return label
    // },
    lockProductName() {
      if (!this.currentBusiness.metadata.hasOwnProperty('lockProductName')) {
        return true
      } else if (this.currentBusiness.metadata.lockProductName) {
        return true
      }
      return false
    },
  },
  watch: {
    // boxQty(val) {
    //   if (this.selectedProduct) {
    //     this.qty = val * this.selectedProduct.unitCountInBox
    //   }
    // },
    qty(val) {
      this.total = this.price * val
    },
    price(val) {
      this.total = (this.price * this.qty).toFixed(2);
    },
    selectedProduct(val) {
      // console.log('i am here test', val)
      if (val) {

        this.setProduct(val);
        this.PriceList.findIndex((price) => {
          if (price.ProductId === val.id) {
            // console.log("found price", price);
            this.price = price.price;
          }
        });

        if (val.metadata && val.metadata.weightApply) {
          this.openWeightDialog = true
          this.weightboxPrice = this.price
        }
      }
    },
    openWeightDialog(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.weightQty.focus();
        }, 300);
      }
    },
    dialog(val) {
      if (val) {
        this.loadingNumber = true;
        this.getSerialList(this.selectedProduct);
      }
    },
  },
  methods: {
    ...mapActions("invoice", ["AddInvoiceDetail", "UnDeleteInvoiceDetailRow"]),
    ...mapActions("global", ["PlaySound"]),
    productSubmitted(val) {

      this.selectedProduct = val
    },
    weightSubmit() {
      console.log('weightSubmit')

      this.submit()
      this.openWeightDialog = false
    },
    closeWeight() {
      this.openWeightDialog = false
    },
    getCustomerPriceList() {
      // // console.log("this.getCustomer", this.Invoice);
      if (this.Invoice && this.Invoice.CustomerId) {
        customerPriceService
          .getAll({ CustomerId: this.Invoice.CustomerId })
          .then((response) => {
            this.PriceList = response.data;
            // // console.log("getCustomerPriceListgetCustomerPriceList", response);
          });
      }
    },
    async restock() {
      const imeiNumber = this.phoneSearch;
      await purchaseService.restock({ imeiNumber: imeiNumber }).then(() => {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "success",
            title: `${imeiNumber} was restock`,
          });
        this.showErrorMessageDialog = false;
        this.phoneSearch = imeiNumber;
        this.searchIMEI();
      });
    },
    closeDialog() {
      this.dialog = false;
      this.emptyEntryBar();
    },
    decrementQuantity(val) {
      if (val.qty > 1)
        val.qty -= 1
    },
    incrementQuantity(val) {
      val.qty += 1
    },

    async getSerialList(product) {
      const list = await purchaseService.getAvailableSerial({
        ProductId: product.id,
      });
      this.serailList = list.data.rows;
      this.loadingNumber = false;
    },
    setProduct(product) {
      if (product) {
        // if (this.currentBusiness?.metadata?.boxCountOnInventory) {
        //   // this.price = product.boxPrice;
        //   let boxPrice = (product.unitCountInBox * product.price).toFixed(2)
        //   boxPrice = parseFloat(boxPrice)
        //   this.total = boxPrice * this.qty;
        // } else {
        //   this.price = product.price;
        //   this.total = product.price * this.qty;
        // }
        // if (this.currentBusiness?.metadata?.boxCountOnInventory) {
        //   this.qty = this.boxQty * product.unitCountInBox
        // }
        this.price = product.price;
        this.total = product.price * this.qty;

        this.name = product.name;
        this.imeiNumber = product.imeiNumber | null;
        if (product.trackSerialNumber) {
          this.disabledQty = true;
        } else {
          this.disabledQty = false;
        }
        if (product.imeiNumber) {
          setTimeout(() => {
            this.$refs.price.focus();
          }, 300);
          // this.$nextTick(() => {
          //   this.$refs.price.focus();
          // });
        } else {
          setTimeout(() => {
            if (this.lockProductName) {
              this.$refs.qty.focus()
            } else {
              this.$refs.itemName.focus();
            }

          }, 300);
          // this.$nextTick(() => {
          //   this.$refs.itemName.focus();
          // });
        }
      }
    },
    emptyEntryBar() {
      this.weight = null
      this.weightQty = null
      this.weightboxPrice = 0
      this.name = null;
      this.qty = 1;
      this.boxQty = 1;
      this.total = 0;
      this.price = 0;
      this.phoneSearch = null;
      this.selectedProduct = null;
      this.dialog = false;
      this.imeiNumber = false;
      this.Products = [];
      this.selected = [];
      setTimeout(() => {
        // this.$refs.productSearch.focus();
        // console.log('selectedProductChild', this.$refs)
        this.$refs.selectedProductChild.$refs.productSearchInput.focus();
      }, 300);
      this.error = []
      if (this.$refs?.selectedProductChild) {
        this.$refs.selectedProductChild.emptyAutoComplete()
      }


    },
    submit() {
      if (this.currentBusiness?.metadata?.lockOutOfStock) {
        if (this.selectedProduct.balance < this.qty) {
          this.PlaySound("error");
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
            })
            .fire({
              icon: "error",
              title: `${this.selectedProduct.name} current stock is ${this.selectedProduct.balance}`,
            });
          return false
        }

      }

      if (!this.selectedProduct) return false;
      if (this.selectedProduct.trackSerialNumber) {
        this.dialog = true;
      } else {
        if (this.selectedProduct.metadata && this.selectedProduct.metadata.weightApply) {
          this.selectedProduct.weight = this.weight
          this.selectedProduct.weightQty = this.weightQty
          // this.selectedProduct.Bar.qty = this.weightQty
          // this.selectedProduct.Bar.price = this.weightboxPrice
          // this.selectedProduct.Bar.name = this.name + ' ' + this.weight + ' lb'
          this.selectedProduct.weightboxPrice = this.weightboxPrice
          this.selectedProduct.weightTotalAmount = this.weightQty * this.weightboxPrice
          this.selectedProduct.cost = ((this.weight * this.selectedProduct.cost) / this.weightQty).toFixed(2)
          this.selectedProduct.Bar = {
            name: this.name + ' ' + this.weight + ' lb' + ' x ' + this.weightboxPrice,
            qty: this.weightQty,
            price: ((this.weight * this.weightboxPrice) / this.weightQty).toFixed(2),
          };
        } else {

          this.selectedProduct.Bar = {
            name: this.name,
            qty: this.qty,
            price: this.price,
            boxQty: this.boxQty,
          };

        }
        console.log('selectedProduct', this.selectedProduct)
        this.$emit("submit", this.selectedProduct);
        this.emptyEntryBar();
      }
    },
    async saveSerialNumbers() {
      await Promise.all(
        this.selected.map((row) => {
          const imeiResult = this.InvoiceDetails.find(
            ({ imeiNumber }) => imeiNumber === row.imeiNumber
          );
          if (imeiResult) {
            this.PlaySound("error");
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "error",
                title: `${row.imeiNumber} is already in this list`,
              });
          } else {
            this.AddInvoiceDetail({
              ProductId: this.selectedProduct.id,
              name: this.name,
              price: this.price,
              total: this.price * 1,
              qty: 1,
              cost: row.cost | 0,
              imeiNumber: row.imeiNumber,
            });
          }
        })
      );

      this.closeDialog();
    },

  },
};
</script>

<style scoped></style>
