var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-lg":""}},[_c('v-card',{attrs:{"loading":_vm.loading,"outlined":""}},[_c('v-toolbar',{attrs:{"dense":"","flat":"","color":"grey lighten-3"}},[(!_vm.CustomerId)?_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1):_vm._e(),_c('v-toolbar-title',[_vm._v(" Sale Cost Adjustment ")])],1),_c('v-card-title',[_c('v-flex',{attrs:{"xs12":"","sm4":""}},[_c('v-text-field',{attrs:{"outlined":"","prepend-inner-icon":"search","rounded":"","dense":"","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),(!_vm.showAll)?_c('v-flex',{attrs:{"sm4":"","xs12":""}},[_c('DateTimePicker',{attrs:{"defaultRange":"today","range":true,"hideTime":true},on:{"submit":function($event){return _vm.getList()}},model:{value:(_vm.dateTime),callback:function ($$v) {_vm.dateTime=$$v},expression:"dateTime"}})],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.Invoices,"search":_vm.search,"sort-desc":_vm.sortDesc,"loading":_vm.loading,"sort-by":_vm.sortBy,"page":_vm.page,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":"","items-per-page":_vm.itemsPerPage},on:{"current-items":_vm.updateTotal,"update:page":function($event){_vm.page=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('tr',{staticClass:"pointer"},[_c('td',{staticClass:"text-left"},[(!isExpanded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),(isExpanded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e()],1),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.date,"MM/DD/YYYY"))+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.localId)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.Customer.name)+" ")]),_c('td',{staticClass:"text-right",on:{"click":function($event){return _vm.viewInvoice(item)}}},[_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"color":"#F3F8FC","flat":"","tile":""}},[_c('v-card-text',{staticClass:"title"},[_c('AdjustCostDetail',{attrs:{"InvoiceId":item.id}})],1)],1)],1)]}},(_vm.Invoices.length > 0)?{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',{style:({ 'background-color': _vm.$style.listTotal.filter })},[_c('td',{staticClass:"text-right font-weight-bold",attrs:{"colspan":headers.length - 1}},[_vm._v(" List Total ")]),_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.total))+" ")])])]}}:null],null,true)},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"warning"},slot:"no-results"},[_vm._v("Your search for \""+_vm._s(_vm.search)+"\" found no results.")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }