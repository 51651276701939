<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar v-if="Customer" :title="`Due Invoice`" />

        <v-container fluid>
          <table class="center">
            <!-- <v-divider class="mx-3"></v-divider> -->
            <tbody class="report-container">
              <v-card-text class="pt-0">
                <v-data-table
                  dense
                  :headers="headers"
                  :expanded.sync="expanded"
                  show-expand
                  :itemsPerPage="Customer.length"
                  hide-default-footer
                  :items="Customer"
                >
                  <template v-slot:item="{ item, isExpanded, expand }">
                    <tr>
                      <td class="text-left ">
                        <v-btn
                          class="no-print"
                          @click="expand(true)"
                          icon
                          v-if="!isExpanded"
                          ><v-icon>mdi-chevron-down</v-icon></v-btn
                        >
                        <v-btn
                          class="no-print"
                          @click="expand(false)"
                          icon
                          v-if="isExpanded"
                          ><v-icon>mdi-chevron-up</v-icon></v-btn
                        >
                      </td>
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-right">{{ item.balance | currency }}</td>
                    </tr>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-0">
                      <v-card color="#F3F8FC" flat>
                        <v-card-text class="title">
                          <DueInvoice :CustomerId="item.id"></DueInvoice>
                        </v-card-text>
                      </v-card>
                    </td>
                  </template>
                  <template
                    v-slot:body.append="{ headers }"
                    v-if="Customer.length > 0"
                  >
                    <tr>
                      <td></td>
                      <td
                        :colspan="headers.length - 3"
                        class="text-right font-weight-bold"
                      >
                        Total
                      </td>
                      <td class="text-right font-weight-bold">
                        {{ total | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
import customerService from "@/modules/Customer/service";
import DueInvoice from "./components/DueInvoice";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      showEmailBtn: false,
      isLoading: true,
      Customer: null,
      expanded: [],
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: "CUSTOMER NAME",
          align: "left",
          value: "name",
          sortable: false,
        },

        {
          text: "BALANCE",
          align: "right",
          value: "balance",
          sortable: false,
        },
      ],
      totalQty: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness", "currentUser", "checkRightStatus"]),
    total() {
      let total = 0;
      this.Customer.map((row) => {
        total += row.balance;
      });

      return total;
    },
  },
  created() {
    this.loadData();
    if (!this.currentBusiness) this.initUser();
  },
  components: { ReportViewBar, DueInvoice },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    loadData() {
      // const id = hashids.decode(this.$route.params.id);
      let where = {
        balanceSheet: true,
        hideZero: true
      };
      if(!this.currentUser.isAdmin && this.checkRightStatus(50)){
        where.SalesRepUserId = this.currentUser.id
      }
      return customerService
        .getAll(where)
        .then((response) => {
          this.$Progress.finish();
          this.Customer = response.data;
          document.title = `Report - Balance Sheet`;

          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
