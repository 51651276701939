<template>
  <div>
    <v-toolbar dense flat>
      <v-app-bar-nav-icon small to="/invoice/home">
        <v-icon>home</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>Invoice</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn
        color="teal"
        :fab="$vuetify.breakpoint.xsOnly"
        v-if="checkRightStatus(7)"
        text
        dark
        @click="newInvoice"
      >
        <span v-if="!$vuetify.breakpoint.xsOnly">New</span>
        <v-icon v-else>add</v-icon>
      </v-btn>
      <v-btn
        color="teal"
        :fab="$vuetify.breakpoint.xsOnly"
        text
        dark
        to="/invoice/list"
      >
        <span v-if="!$vuetify.breakpoint.xsOnly">List</span>
        <v-icon v-else>list</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
        <router-view></router-view>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RegisterStoreModule from "@/mixins/RegisterStoreModule";
import invoiceStore from "./store.js";

export default {
  name: "invoice",
  mixins: [RegisterStoreModule],
  data() {
    return {};
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
    ...mapGetters("invoice", ["InvoiceDetails"]),
  },
  methods: {
    ...mapActions("invoice", ["ResetInvoice"]),
    newInvoice() {
      if (this.InvoiceDetails.length !== 0) {
        this.$swal({
          title: "Are you sure?",
          text: "There is an running invoice you want to clear it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes"
        }).then(result => {
          if (result.value) {
            this.ResetInvoice();
            if (this.CustomerId) {
              this.$router.push({
                path: `/invoice/create?CustomerId=${this.CustomerId}`
              });
            } else {
              this.$router.push({ path: `/invoice/create` });
            }
          }
        });
      } else {
        if (this.CustomerId) {
          this.$router.push({
            path: `/invoice/create?CustomerId=${this.CustomerId}`
          });
        } else {
          this.$router.push({ path: `/invoice/create` });
        }
      }
    }
  },
  created() {
    this.registerStoreModule("invoice", invoiceStore);
  }
};
</script>
