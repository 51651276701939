<template>
  <v-container fluid grid-list-lg>
    <v-card :loading="loading" outlined>
      <v-toolbar dense flat color="grey lighten-3">
        <v-app-bar-nav-icon v-if="!CustomerId" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title> Sale Cost Adjustment </v-toolbar-title>
      </v-toolbar>

      <v-card-title>
        <v-flex xs12 sm4>
          <v-text-field
            v-model="search"
            outlined
            prepend-inner-icon="search"
            rounded
            dense
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex v-if="!showAll" sm4 xs12>
          <DateTimePicker
            defaultRange="today"
            :range="true"
            :hideTime="true"
            v-model="dateTime"
            @submit="getList()"
          />
        </v-flex>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="Invoices"
          @current-items="updateTotal"
          :search="search"
          :sort-desc="sortDesc"
          :loading="loading"
          :sort-by="sortBy"
          :page.sync="page"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
          :items-per-page="itemsPerPage"
        >
          <template v-slot:item="{ item, isExpanded, expand }">
            <tr class="pointer">
              <td class="text-left">
                <v-btn @click="expand(true)" icon v-if="!isExpanded"
                  ><v-icon>mdi-chevron-down</v-icon></v-btn
                >
                <v-btn @click="expand(false)" icon v-if="isExpanded"
                  ><v-icon>mdi-chevron-up</v-icon></v-btn
                >
              </td>
              <td class="text-left">
                {{ item.date | moment("MM/DD/YYYY") }}
              </td>
              <td class="text-left">
                {{ item.localId }}
              </td>
              <td class="text-left">
                {{ item.Customer.name }}
              </td>
              <td class="text-right" @click="viewInvoice(item)">
                {{ item.total | currency }}
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0">
              <v-card color="#F3F8FC" flat tile>
                <v-card-text class="title">
                  <AdjustCostDetail :InvoiceId="item.id"></AdjustCostDetail>
                </v-card-text>
              </v-card>
            </td>
          </template>
          <template v-slot:body.append="{ headers }" v-if="Invoices.length > 0">
            <tr :style="{ 'background-color': $style.listTotal.filter }">
              <td
                :colspan="headers.length - 1"
                class="text-right font-weight-bold"
              >
                List Total
              </td>
              <td class="text-right font-weight-bold">
                {{ total | currency }}
              </td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import invoiceService from "../service";
const DateTimePicker = () => import("@/components/DateTimePicker");
import AdjustCostDetail from "./AdjustCostDertail";

export default {
  props: {
    CustomerId: {
      default: null,
    },
    showAll: {
      default: false,
    },
    due: {
      default: false,
    },
  },
  name: "adjustment-list",
  data() {
    return {
      expanded: [],
      singleExpand: true,
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: this.$t("labels.date"),
          align: "left",
          value: "date",
          sortable: true,
        },
        {
          text: "INVOICE NUMBER",
          align: "left",
          value: "lcoalId",
          sortable: true,
        },
        {
          text: this.$t("labels.customerName"),
          align: "left",
          value: "Customer.Name",
          sortable: true,
        },
        {
          text: this.$t("labels.total"),
          align: "left",
          value: "total",
          sortable: true,
        },
      ],
      fullTotal: 0,
      fullBalance: 0,
      total: 0,
      balance: 0,
      fab: false,
      dateTime: {
        start: this.$moment().startOf("day").toISOString(),
        end: this.$moment().endOf("day").toISOString(),
      },
      createDialog: false,
      selectedPayee: null,
      Invoices: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "localId",
      pageCount: 0,
      sortDesc: true,
      itemsPerPage: 10,
    };
  },
  components: {
    DateTimePicker,
    AdjustCostDetail,
  },
  computed: {
    ...mapGetters("global", [
      "currentUser",
      "checkRightStatus",
      "currentBusiness",
    ]),
    ...mapGetters("invoice", ["InvoiceDetails", "getEditInvoiceId"]),
  },
  methods: {
    ...mapActions("invoice", ["ResetInvoice"]),
    updateTotal(val) {
      this.total = 0;
      this.balance = 0;
      val.map((row) => {
        this.total += row.total;
        this.balance += row.due;
      });
    },
    add() {
      if (this.InvoiceDetails.length !== 0) {
        this.$swal({
          title: "Are you sure?",
          text: "There is an running invoice you want to clear it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.ResetInvoice();
            if (this.CustomerId) {
              this.$router.push({
                path: `/invoice/create?CustomerId=${this.CustomerId}`,
              });
            } else {
              this.$router.push({ path: `/invoice/create` });
            }
          }
        });
      } else {
        if (this.CustomerId) {
          this.$router.push({
            path: `/invoice/create?CustomerId=${this.CustomerId}`,
          });
        } else {
          this.$router.push({ path: `/invoice/create` });
        }
      }
    },
    getList() {
      this.loading = true;
      let where = {};
      if (!this.showAll) {
        where = {
          start: this.dateTime.start,
          end: this.dateTime.end,
        };
      }
      if (this.CustomerId) {
        where.CustomerId = this.CustomerId;
      }

      if (this.due) {
        where.due = true;
      }
      return invoiceService.getAll(where).then((response) => {
        // console.log("invoice", response);
        this.loading = false;
        this.Invoices = response.data;
        this.fullTotal = 0;
        this.fullBalance = 0;
        this.Invoices.map((row) => {
          this.fullTotal += row.total;
          this.fullBalance += row.due;
        });
        return response;
      });
    },
    editInvoice(invoice) {
      // // console.log('ddf', invoice)
      this.$router.push({ path: `/invoice/${invoice.id}/edit` });
    },
    deleteInvoice(invoice) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          invoiceService
            .delete(invoice.id)
            .then((response) => {
              if (response.data) {
                this.$swal("Deleted!", "Invoice has been deleted.", "success");
                this.getList();
                this.$events.emit("customerProfileEvent");
              }
            })
            .catch((error) => {
              this.$swal({
                title: "Delete Invoice",
                text: error.data.message,
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>
