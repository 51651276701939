<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar v-if="Invoices" :title="`Due Invoice By Date`" :start="start" :end="end" />

        <v-container fluid>
          <table class="center">
            <!-- <v-divider class="mx-3"></v-divider> -->
            <tbody class="report-container">
              <v-card-text class="pt-0">
                <!-- <v-expansion-panel-content lazy v-for="(invoice, i) in Object.keys(GroupInvoice)" :key="i">
                  <template v-slot:header>
                    <v-layout row wrap>
                      <v-flex>
                        <h2 class="title font-weight-regular">{{ invoice }} ({{ Object.entries(GroupInvoice)[i][1].length
                        }})</h2>
                      </v-flex>
                      <v-spacer></v-spacer>
                      <v-flex class="text-xs-right mr-2" shrink>
                        <h2 class="title font-weight-regular">{{ getTotal(i) | currency }}</h2>
                      </v-flex>
                    </v-layout>
                  </template>
                </v-expansion-panel-content> -->
                <v-data-table dense :headers="headers" :expanded.sync="expanded" show-expand 
                  item-key="name"
                  :itemsPerPage="GroupInvoice.length" hide-default-footer :items="GroupInvoice">
                  <template v-slot:item="{ item, isExpanded, expand }">
                    <tr>
                      <td class="text-left">
                        <v-btn class="no-print" @click="expand(true)" icon v-if="!isExpanded">
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                        <v-btn class="no-print" @click="expand(false)" icon v-if="isExpanded">
                          <v-icon>mdi-chevron-up</v-icon>
                        </v-btn>
                      </td>
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-right">{{ item.balance | currency }}</td>
                    </tr>
                  </template>
                  template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-0">
                      <v-card color="#F3F8FC" flat>
                        <v-card-text class="title">
                          <DueInvoice :preLoadedData="item.value" ></DueInvoice>
                        </v-card-text>
                      </v-card>
                    </td>
                  </template>
                  <template v-slot:body.append="{ headers }" v-if="Invoices.length > 0">
                    <tr>
                      <td></td>
                      <td :colspan="headers.length - 3" class="text-right font-weight-bold">
                        Total
                      </td>
                      <td class="text-right font-weight-bold">
                        {{ total | currency }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
// import customerService from "@/modules/Customer/service";
import invoiceService from "@/modules/Invoice/service.js"
import DueInvoice from "./components/DueInvoice";

import _ from 'lodash';
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      start: null,
      end: null,
      showEmailBtn: false,
      isLoading: true,
      Invoices: null,
      expanded: [],
      GroupInvoice: [],
      headers: [
        { text: "", value: "data-table-expand" },
        {
          text: "CUSTOMER NAME",
          align: "left",
          value: "name",
          sortable: false,
        },

        {
          text: "BALANCE",
          align: "right",
          value: "balance",
          sortable: false,
        },
      ],
      totalQty: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    total() {
      let total = 0;
      this.GroupInvoice.map((row) => {
        total += row.balance;
      });

      return total;
    },
  },
  created() {
    this.start = this.$route.query.start;
    this.end = this.$route.query.end;
    this.loadData();
    if (!this.currentBusiness) this.initUser();
  },
  components: { ReportViewBar, DueInvoice },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    loadData() {
      // const id = hashids.decode(this.$route.params.id);
      let where = {
        due: true,
      };
      if (this.start) where.start = this.start;
      if (this.end) where.end = this.end;


      return invoiceService
        .getAll(where)
        .then((response) => {
          console.log('response', response)
          this.$Progress.finish();
          this.Invoices = response.data;
          document.title = `Report - Open Invoice By Date`;
          const GroupInvoice = _.mapValues(
            _.groupBy(this.Invoices, invoice =>
              invoice.Customer.name),
            list => list,
          );
          console.log('GroupInvoice', GroupInvoice)
          // for (let index = 0; index < this.Object.keys(this.GroupInvoice).length; index++) {
          //   const element = this.Object.keys(this.GroupInvoice)[index];
          //   console.log('element', element)
          // }
          for (const property in GroupInvoice) {
            // console.log(`${property}: ${this.GroupInvoice[property]}`);
            let total = 0
            GroupInvoice[property].map(row => {
              total += row.total
            })
            this.GroupInvoice.push({
              customerId: GroupInvoice[property][0].CustomerId,
              name: property,
              balance: total,
              value: GroupInvoice[property]
            })
          }
          console.log('test', this.GroupInvoice)


          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
