<template>
  <v-card outlined>
    <v-card-text>
      <!-- <v-btn color="success" @click="fix()">fix</v-btn> -->
      <v-layout row wrap align-center>
        <v-flex shrink v-if="InvoiceDetails.length > 0">
          <v-btn-toggle v-model="showCost"  color="deep-purple accent-3" dense group>
            <v-btn :value="1" text class="ma-0" outlined>
              <v-icon>mdi-currency-usd</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-flex>
        <v-flex shrink v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.serializeProduct">
          <ReturnPhone @submit="insertReturn" />
        </v-flex>
        <v-flex>
          <v-chip v-if="totalQty !== 0" class="ma-2" color="indigo lighten-2" text-color="white">
            <v-avatar left color="indigo " class="mr-2">
              <v-icon small color="white">mdi-cart-outline</v-icon>
            </v-avatar>
            <span class="title">{{ totalQty }}</span>
          </v-chip>
        </v-flex>
        <v-flex v-if="replaceQty !== 0" shrink>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-bind="attrs" v-on="on" class="ma-2" color="yellow lighten-2" text-color="black">
                <v-avatar left color="yellow" class="mr-2">
                  <v-icon small color="black">mdi-barcode-scan</v-icon>
                </v-avatar>
                <span class="title">{{ replaceQty }}</span>
              </v-chip>
            </template>
            <span>Serial Item need to be replace</span>
          </v-tooltip>
        </v-flex>
        <!-- <v-flex shrink>
          <v-chip v-if="totalBoxes !== 0" class="ma-2" color="green lighten-2" text-color="white">
            <v-avatar left color="green " class="mr-2">
              <v-icon small color="white">mdi-package-variant-closed</v-icon>
            </v-avatar>
            <span class="title">{{ totalBoxes }}</span>
          </v-chip>
        </v-flex> -->
        <v-spacer></v-spacer>
        <!-- <v-flex xs12 sm4>
          <v-text-field
            v-model="search"
            outlined
            prepend-inner-icon="search"
            rounded
            dense
            label="Search"
            single-line
            hide-details
            clearable
          >
          </v-text-field>
        </v-flex> -->
      </v-layout>

      <v-data-table id="invoice-cart" :search="search" :headers="headers" hide-default-footer :itemsPerPage="1000"
        no-data-text="No item on this invoice yet" :items="InvoiceDetails">
        <template v-slot:item="{ item, index }">
          <tr :style="{ 'background-color': activeColor(item) }">
            <td>
              <v-layout align-center justify-start row fill-height wrap>
                <v-btn icon @click="deleteItem(index)" v-if="!item.delete">
                  <v-icon color="red">delete</v-icon>
                </v-btn>
                <v-btn icon @click="unDeleteItem(index)" v-else>
                  <v-icon color="green">mdi-delete-empty</v-icon>
                </v-btn>
                <!-- <div>{{ item.boxQty  }}</div>
                <div>/{{ item.price *  item.unitCountInBox}} = {{ item.price *  item.unitCountInBox * item.boxQty }}</div> -->
                <v-text-field v-if="!item.delete" v-model="item.name" :disabled="lockProductName" outlined hide-details
                  dense></v-text-field>
                  
                <span v-else :class="{ deleted: item.delete }">{{
                  item.name
                  }}</span>
                  
              </v-layout>
            </td>
            <td class="text-left"
              v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.serializeProduct">
              <div v-if="
                item.Product &&
                item.Product.trackSerialNumber &&
                item.type === 'order'
              ">
                <v-chip class="ma-2" width="100%" label color="yellow">
                  Replace With Serial Numbers & Delete It
                </v-chip>
                <!-- <v-btn color="success" @click="openIMEICollect(item)" block>Attach Serial Number</v-btn> -->
              </div>
              <div v-else>
                <v-text-field v-if="!item.delete" v-model="item.imeiNumber" outlined disabled hide-details
                  dense></v-text-field>
                <span v-else :class="{ deleted: item.delete }">{{
                  item.imeiNumber
                  }}</span>
              </div>
            </td>
            <!-- <td class="text-center"
              v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">
              <v-text-field v-if="!item.delete" v-model="item.boxQty" :disabled="item.imeiNumber ? true : false"
                outlined @blur="checkPrice(item)" type="number" class="centered-input" hide-details dense
                @focus="$event.target.select()"></v-text-field>

              <span v-else :class="{ deleted: item.delete }">{{
                item.boxQty 
                }}</span>
            </td> -->
            
            <td class="text-center">
              <v-text-field v-if="!item.delete" v-model="item.qty" :disabled="item.imeiNumber ? true : false" outlined
                type="number" class="centered-input" hide-details dense @focus="$event.target.select()"
                @blur="checkPrice(item)"></v-text-field>
              <span v-else :class="{ deleted: item.delete }">{{
                item.qty
                }}</span>
            </td>
            <td v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.boxCountOnInventory">
              <div :class="{ deleted: item.delete }" class="text-center">{{ item.unitCountInBox }}</div>
            </td>

            <td class="text-right">
              <v-text-field v-if="!item.delete" v-model="item.price" outlined :min="0"
                prepend-inner-icon="mdi-clipboard-check-outline" class="al-r" type="number"
                @click:prepend-inner="copyPrice(item, index)" hide-details @blur="checkPrice(item)"
                @focus="$event.target.select()" dense></v-text-field>
              <span v-else :class="{ deleted: item.delete }">{{
                item.price | currency
                }}</span>
            </td>

            <td class="text-right">
              <span :class="{ deleted: item.delete }" :style="{
                color:
                  parseFloat(item.price) > parseFloat(item.cost)
                    ? 'green'
                    : 'red',
              }">{{ (item.total) | currency }}</span>
              <span v-if="showCost">
                <span v-if="checkRightStatus(42)">
                  <v-text-field v-if="showCost" v-model="item.cost" outlined class="al-r" hide-details
                    @focus="$event.target.select()" dense></v-text-field>
                </span>

                <span v-else>/{{ item.cost | currency }}</span>
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
const ReturnPhone = () => import("./ReturnPhone");

import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      openDialog: false,
      selectedItem: null,
      htmlHeight: 0,
      showCost: false,
      search: null,
      headers: [],
    };
  },
  computed: {
    ...mapGetters("invoice", [
      "InvoiceDetails",
      "totalQty",
      "totalBoxes",
      "getSelectedCustomer",
    ]),
    ...mapGetters("global", [
      "currentUser",
      "checkRightStatus",
      "currentBusiness",
    ]),
    lockProductName() {
      if (!this.currentBusiness.metadata.hasOwnProperty('lockProductName')) {
        return true
      } else if (this.currentBusiness.metadata.lockProductName) {
        return true
      }
      return false
    },
    replaceQty() {
      let value = 0;
      this.InvoiceDetails.map((row) => {
        if (row.needIMEINumber && !row.delete) value += row.qty;
      });

      return value;
    },
    selectedCustomer: {
      get: function () {
        return this.getSelectedCustomer;
      },
      set: function (val) {
        this.SetSelectedCustomer(val);
      },
    },
  },
  created() {
    // console.log('thsi.currentBusiness', this.currentBusiness.metadata)
    this.headers.push({
      text: "Name",
      align: "left",
      value: "name",
      sortable: false,
    });

    if (this.currentBusiness?.metadata?.serializeProduct) {
      this.headers.push({
        text: "Serial Number",
        align: "left",
        value: "imeiNumber",
        sortable: false,
      });
    }


    // if (this.currentBusiness?.metadata?.boxCountOnInventory) {
    //   this.headers.push(
    //     {
    //       text: 'Box Qty',
    //       align: "center",
    //       value: "boxQty",
    //       width: "100",
    //       sortable: false,
    //     },
    //   );
    // }

    this.headers.push(
      {
        text: 'Qty',
        align: "center",
        value: "qty",
        width: "100",
        sortable: false,
      },
    );

    if (this.currentBusiness?.metadata?.boxCountOnInventory) {
      this.headers.push(
        {
          text: "Unit Count",
          align: "right",
          value: "unitCountInBox",
          sortable: false,
        }
      );
    }

    this.headers.push(
      {
        text: 'Price',
        align: "right",
        value: "price",
        sortable: false,
      },
      {
        text: "Total",
        align: "right",
        value: "total",
        sortable: false,
      }
    );


  },
  components: {
    ReturnPhone,
  },
  watch: {
    selectedCustomer() {
      this.changeHeight();
    },
  },
  mounted() {
    this.htmlHeight = document.documentElement.offsetHeight;
    this.changeHeight();
  },
  methods: {
    ...mapActions("invoice", [
      "AddInvoiceDetail",
      "SetSelectedCustomer",
      "DeleteInvoiceDetailRow",
      "UnDeleteInvoiceDetailRow",
      "CopyPriceToAll",
    ]),
    fix() {
      console.log('this.InvoiceDetail', this.InvoiceDetails)
      // <div>{{ item.boxQty  }}</div>
      // <div>/{{ item.price *  item.unitCountInBox}} = {{ item.price *  item.unitCountInBox * item.boxQty }}</div>
      this.InvoiceDetails.map(row => {
        row.qty = row.boxQty
        row.price = parseFloat(row.price) * parseFloat(row.unitCountInBox)
      })
    },
    openIMEICollect(item) {
      // console.log("click here");
      this.openDialog = true;
      this.selectedItem = item;
    },
    activeColor(item) {
      let result = "transparent";
      if (item.receivedAt) result = "#FFFDE7";
      return result;
    },
    changeHeight() {
      let html = this.htmlHeight;
      let alert = document.getElementById("alert-col");

      if (alert) {
        alert = alert.offsetHeight;
      } else {
        alert = 0;
      }
      let newHeight = html - (580 + alert) + "px";
      document.getElementById("invoice-cart").style.height = newHeight;
    },
    copyPrice(item, index) {
      this.$swal({
        title: "Change Price To All",
        text: `You want to change all ${item.name
          } price to ${this.$options.filters.currency(item.price)}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.CopyPriceToAll(index);
        }
      });
    },
    async unDeleteItem(i) {
      await this.UnDeleteInvoiceDetailRow(i);
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
        })
        .fire({
          icon: "success",
          title: "Item was restore",
        });
    },
    deleteItem(i) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.DeleteInvoiceDetailRow(i);
          // this.Invoice.InvoiceDetails.splice(i, 1);
        }
      });
    },
    // checkPrice(item) {
    //   if (parseFloat(item.price) < item.cost) {
    //     this.$swal({
    //       title: "Selling Blow Cost Price",
    //       text: `${item.name} cost price is ${this.$options.filters.currency(
    //         item.cost
    //       )}. You are selling it for ${this.$options.filters.currency(
    //         item.price
    //       )}below cost price. Are you sure?`,
    //       icon: "question",
    //       showCancelButton: true,
    //       confirmButtonColor: "#3085d6",
    //       cancelButtonColor: "#d33",
    //       confirmButtonText: "Yes",
    //       cancelButtonText: "No",
    //     }).then((result) => {
    //       if (!result.value) {
    //         item.price = item.previousPrice;
    //         // if (this.currentBusiness?.metadata?.boxCountOnInventory) {
    //         //   item.total = (parseFloat(item.unitCountInBox) * parseFloat(item.boxQty)) * parseFloat(item.price);
    //         // } else {
    //           item.total = parseFloat(item.price) * parseFloat(item.qty);
    //         // }
    //       }
    //     });
    //   } else {
    //     console.log('row value', item)
    //     item.total = parseFloat(item.price) * parseFloat(item.qty);
    //   }
    // },
    checkPrice(item) {
      // console.log('item', item.price , item.qty, parseFloat(item.price) * parseFloat(item.qty))
      const updateTotal = () => {
        // item.total = parseFloat(item.price) * parseFloat(item.qty);
        // this.$set(item, 'total', parseFloat(item.price) * parseFloat(item.qty));
        // this.$forceUpdate();
        const newTotal = parseFloat(item.price) * parseFloat(item.qty);
        console.log('New Total:', newTotal);
        this.$set(item, 'total', newTotal);
        // this.$nextTick(() => {
        //   this.$forceUpdate();
        // });
        setTimeout(() => {
          
          this.$forceUpdate();
          console.log('test', this.InvoiceDetails)
        }, 1000);
      };

      if (parseFloat(item.price) < item.cost) {
        this.$swal({
          title: "Selling Below Cost Price",
          text: `${item.name} cost price is ${this.$options.filters.currency(item.cost)}. You are selling it for ${this.$options.filters.currency(item.price)}, which is below the cost price. Are you sure?`,
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.value) {
            updateTotal();
          } else {
            item.price = item.previousPrice;
            updateTotal();
          }
        });
      } else {
        updateTotal();
      }


    },
    insertReturn(val) {
      // console.log("insert return", val);
      const imeiResult = this.InvoiceDetails.find(
        ({ imeiNumber }) => imeiNumber === val.imeiNumber
      );

      if (imeiResult) {
        this.$swal({
          title: "Return Item",
          text: "This item is already in list",
          icon: "error",
        });
        // this.$swal
        //   .mixin({
        //     toast: true,
        //     position: "top-right",
        //     showConfirmButton: true,
        //     timer: 3000,
        //   })
        //   .fire({
        //     icon: "error",
        //     title: `${val.imeiNumber} is already in this list`,
        //   });
      } else {
        this.AddInvoiceDetail({
          ProductId: val.ProductId,
          name: val.name,
          price: Math.abs(val.returnPrice),
          qty: -1,
          total: val.price * -1,
          cost: val.cost,
          imeiNumber: val.imeiNumber,
          lock: true,
        });
      }
    },
  },
};
</script>

<style scoped>
#invoice-cart {
  overflow: auto;
}
</style>
