<template>
  <div>
    <v-card-text class="pb-0">
      <v-row>
        <v-col>
          <span>Select Customer</span>
          <AutoComplete :disabled="disableCustomer"
           v-model="selectedCustomer" @reset="$emit('reset')" @change="customerChange"
            :initValue="setCustomer" :errorMessages="selectCustomerError" />
        </v-col>
        <v-col>
          <span>Select Salesman</span>
          <SalesMan v-model="selectedUser" :initValue="initRep" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-expansion-panels v-if="selectedCustomer" flat>
      <v-expansion-panel>
        <v-expansion-panel-header class="pt-0 pb-0">
          <v-row align="center" justify="space-between">
            <v-col>
              <span class="font-weight-medium text-capitalize">
                {{ selectedCustomer.name }}</span>
              <span class="ml-1">{{ selectedCustomer.address }}
                <span v-if="selectedCustomer.city">{{ selectedCustomer.city }},
                  <span>{{ selectedCustomer.state }}</span>
                  {{ selectedCustomer.zip }}</span>
              </span>
            </v-col>

            <v-col>
              <div class="text-right subtitle-1 font-weight-regular">
                <span>Balance: </span>
                <span class="font-weight-bold">{{
                selectedCustomer.balance | currency
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card outlined>
            <v-card-text>
              <v-row>
                <v-col v-if="selectedCustomer">
                  <v-textarea readonly outlined hide-details dense v-model="selectedCustomer.note"
                    label="Customer Note"></v-textarea>
                </v-col>
                <v-col>
                  <v-textarea outlined dense hide-details v-model="Invoice.note" label="Invoice Note"></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <DateSelector label="Due Date" :initValue="initDueDate" v-model="Invoice.dueDate" />
                </v-col>
                <v-col>
                  <DateSelector label="Date" :initValue="initDate" v-model="Invoice.date" />
                </v-col>
                <v-col>
                  <v-text-field outlined hide-details dense v-model="trackingNumber" label="Tracking Number">
                  </v-text-field>
                </v-col>
                <v-col class="shrink">
                  <v-btn rounded dark @click="editDialog = true" color="purple">
                    <v-icon class="mr-2" small>edit</v-icon>
                    Edit
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import DateSelector from "@/components/DateSelector";
import { mapGetters, mapActions } from "vuex";
import AutoComplete from "@/modules/Customer/components/AutoComplete";
import SalesMan from "@/modules/User/components/AutoComplete.vue";
// const Edit = () => import("@/modules/Customer/components/Edit");

// this.$moment().toISOString(),
export default {
  props: {
    selectCustomerError: {
      default: function () {
        return [];
      },
    },
    initCustomer: {
      type: Object,
      default: null,
    },
    initUser: {
      default: null,
    },
    initInvoice: {
      default: null,
    },
  },
  data() {
    return {
      disableCustomer: false,
      editDialog: false,
      initRep: null,
      setCustomer: null,
      initDate: null,
      initDueDate: null,
    };
  },
  components: {
    DateSelector,
    AutoComplete,
    SalesMan,
  },
  created() {
    // // console.log("this.Invoice.Date", this.Invoice.date);

    if (this.Invoice.date) {
      this.SetInvoiceDate(this.$moment(this.Invoice.date).toISOString());
    } else {
      this.SetInvoiceDate(this.$moment().toISOString());
    }

    if (this.Invoice.dueDate) {
      this.SetInvoiceDate(this.$moment(this.Invoice.dueDate).toISOString());
    } else {
      this.SetInvoiceDate(this.$moment().toISOString());
    }
    // this.SetInvoiceDate(this.$moment(this.Invoice.date).toISOString());
    // this.SetInvoiceDueDate(this.$moment(this.Invoice.dueDate).toISOString());

    // if (this.$route.query && this.$route.query.CustomerId) {
    //   const id = this.$route.query.CustomerId;
    // this.initCustomer = id;
    // }
  },
  watch: {
    initUser(val) {
      this.initRep = val.SalesRep;
    },
    initInvoice(val) {
      console.log('initInvoice', val)
      this.initDate = val.date;
      this.initDueDate = val.dueDate;
      if (val.PaymentDetails.length > 0) {
        this.disableCustomer = true
      } else {
        this.disableCustomer = false
      }
    },
    initCustomer(val) {
      // console.log('initCustomer', val)
      this.initRep = this.initCustomer.SalesRep;
      this.setCustomer = val;
    },
  },
  computed: {
    ...mapGetters("invoice", [
      "InvoiceDetails",
      "Invoice",
      "invoiceTotal",
      "getSelectedUser",
      "getSelectedCustomer",
      "getTrackingNumber",
      "currentUser",
    ]),
    ...mapGetters("global", ["currentUser", "currentBusiness"]),
    invoiceDueDate: {
      get: function () {
        return this.getInvoiceDueDate;
      },
      set: function (val) {
        this.SetInvoiceDueDate(val);
      },
    },
    invoiceDate: {
      get: function () {
        return this.getInvoiceDate;
      },
      set: function (val) {
        this.SetInvoiceDate(val);
      },
    },
    invoiceNote: {
      get: function () {
        return this.getInvoiceNote;
      },
      set: function (val) {
        this.SetInvoiceNote(val);
      },
    },
    trackingNumber: {
      get: function () {
        return this.getTrackingNumber;
      },
      set: function (val) {
        this.SetTrackingNumber(val);
      },
    },
    selectedCustomer: {
      get: function () {
        return this.getSelectedCustomer;
      },
      set: function (val) {
        this.SetSelectedCustomer(val);
      },
    },
    selectedUser: {
      get: function () {
        return this.getSelectedUser;
      },
      set: function (val) {
        this.SetSelectedUser(val);
      },
    },
  },
  methods: {
    ...mapActions("invoice", [
      "SetSelectedCustomer",
      "SetTrackingNumber",
      "SetInvoiceDueDate",
      "SetInvoiceDate",
      "SetSelectedUser",
    ]),
    customerChange(val) {
      if (val && val.SalesRep) {
        this.initRep = val.SalesRep;
      }
      if(val && val.metadata && val.metadata.invoiceDue){
        this.initDueDate = this.$moment().add(val.metadata.invoiceDue.value, 'days').toISOString();
        this.SetInvoiceDueDate(this.$moment().add(val.metadata.invoiceDue.value, 'days').toISOString())
      } else if(this.currentBusiness.metadata.invoiceDue){
        this.initDueDate = this.$moment().add(this.currentBusiness.metadata.invoiceDue.value, 'days').toISOString();
        this.SetInvoiceDueDate(this.$moment().add(this.currentBusiness.metadata.invoiceDue.value, 'days').toISOString())
      } else {
        this.initDueDate = this.$moment().toISOString();
        this.SetInvoiceDueDate(this.$moment().toISOString())
      }
    },
  },
};
</script>
