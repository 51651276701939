<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar v-if="Invoice" :title="`Refund Invoices`" />

        <v-container fluid>
          <table class="center">
            <!-- <v-divider class="mx-3"></v-divider> -->
            <tbody class="report-container">
              <v-card-text class="pt-0">
                <v-data-table
                  dense
                  :headers="headers"
                  :itemsPerPage="Invoice.length"
                  hide-default-footer
                  :items="Invoice"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-left">
                        <a href="#" @click="viewInvoice(item)">{{
                          item.localId
                        }}</a>
                      </td>
                      <td class="text-left">
                        {{ item.date | moment("MM/DD/YYYY") }}
                      </td>
                      <td class="text-left">{{ item.Customer.name }}</td>
                      <td class="text-right">{{ item.total | currency }}</td>
                      <td class="text-right">{{ item.due | currency }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
import invoiceService from "@/modules/Invoice/service";
import { mapGetters, mapActions } from "vuex";
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  data() {
    return {
      start: null,
      end: null,
      isLoading: true,
      Invoice: null,
      headers: [
        {
          text: "INVOICE NUMBER",
          align: "left",
          value: "localId",
          sortable: false,
        },
        {
          text: "CREATED AT",
          align: "left",
          value: "date",
          sortable: false,
        },
        {
          text: "CUSTOMER NAME",
          align: "left",
          value: "Customer.name",
          sortable: false,
        },
        {
          text: "INVOICE AMOUNT",
          align: "right",
          value: "total",
          sortable: false,
        },
        {
          text: "DUE",
          align: "right",
          value: "due",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    total() {
      let total = 0;
      this.Invoice.map((row) => {
        total += row.balance;
      });
      return total;
    },
  },
  created() {
    this.loadData();
    if (!this.currentBusiness) this.initUser();
  },
  components: { ReportViewBar },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    viewInvoice(invoice) {
      const hashId = hashids.encode(invoice.id);
      let routeData = null;
      if (this.$route.meta.public) {
        routeData = this.$router.resolve({
          path: `/public/invoice/${hashId}/view`,
        });
      } else {
        routeData = this.$router.resolve({
          path: `/global/invoice/${hashId}/view`,
        });
      }

      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    loadData() {
      this.start = this.$route.query.start;
      this.end = this.$route.query.end;

      return invoiceService
        .getAll({ refund: true, start: this.start, end: this.end })
        .then((response) => {
          this.$Progress.finish();
          this.Invoice = response.data;
          document.title = `Report - Refund Invoice`;

          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
  .no-print {
    display: none;
  }
  .detailBox {
    overflow: visible;
    height: 100%;
  }
  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}
.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}
.center {
  background-color: white;
  margin: auto;
  width: 100%;
}
/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
