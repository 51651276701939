<template>
  <v-container grid-list-lg fluid>
    <v-alert v-if="selectedCustomer" border="left" type="error" transition="slide-y-transition" dismissible class="mb-1"
      v-model="selectedCustomer.alert" color="red">
      <strong>{{ selectedCustomer.note }}</strong>
    </v-alert>
    <v-layout column>
      <v-flex>
        <v-card outlined>
          <v-toolbar dense flat color="grey lighten-3">
            <v-toolbar-title>
              Edit Invoice <span># {{ Invoice.localId }}</span>
              <v-chip color="red lighten-3" label small v-if="Invoice.due === 0" text-color="red darken-4">Paid</v-chip>
            </v-toolbar-title>
            <v-spacer> </v-spacer>
            <v-row justify="end">
              <v-col class="shrink">
                <v-btn color="pink" small :dark="disabled ? false : true" :loading="loading" :disabled="disabled"
                  @click="saveInvoice">{{ invoiceTotal | currency }} update</v-btn>
              </v-col>
              <v-col class="shrink">
                <v-btn small text @click.stop.prevent="cancel">Cancel</v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
          <SelectCustomer :initInvoice="initInvoice" :initCustomer="initCustomer" :initUser="initUser" />
        </v-card>
      </v-flex>
      <v-flex>
        <!-- <v-btn color="success" @click="fix">Fix Detail</v-btn> -->
        <ProductSearchBar @submit="addRow" />
      </v-flex>
      <v-flex>
        <Cart />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import invoiceService from "../service";
import ProductSearchBar from "@/modules/Invoice/components/ProductSearchBar";
import Cart from "@/modules/Invoice/components/Cart";
// import productService from '@/modules/Product/service.js'
import SelectCustomer from "@/modules/Invoice/components/SelectCustomer";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "edit-invoice",
  data() {
    return {
      initCustomer: null,
      initInvoice: null,
      initUser: null,
      loading: false,
    };
  },
  components: {
    Cart,
    SelectCustomer,
    ProductSearchBar,
  },
  computed: {
    ...mapGetters("invoice", [
      "Invoice",
      "InvoiceDetails",
      "invoiceTotal",
      "getEditInvoiceId",
      "getSelectedUser"
    ]),
    ...mapGetters("global", [
      "currentBusiness",
    ]),
    selectedCustomer: {
      get: function () {
        return this.getSelectedCustomer;
      },
      set: function (val) {
        this.SetSelectedCustomer(val);
      },
    },
    disabled() {
      let value = true;
      value = this.InvoiceDetails.length === 0;

      if(!value){
        if (this.currentBusiness.metadata && this.currentBusiness.metadata.lockInvoiceSalesRep && !this.getSelectedUser)
          value = true
      }
      return value;
    },
  },
  created() {
    console.log("Invoice", this.Invoice);
    // if (!this.Invoice.localId) {
    this.loadData();
    // }
  },
  methods: {
    ...mapActions("invoice", [
      "AddInvoiceDetail",
      "ResetInvoice",
      "loadInvoice",
      "SetEditInvoiceId",
    ]),
    fix() {
      // console.log('invoice detail', this.InvoiceDetails)
      this.InvoiceDetails.map(row => {
        // console.log('row', row)
        row.unitCountInBox = row.Product.unitCountInBox
        row.boxQty = row.qty
        row.qty = row.boxQty * row.unitCountInBox
        row.total = row.boxQty * row.price
        row.price = (row.total / row.qty).toFixed(2)
      })
    },
    cancel() {
      if (this.InvoiceDetails.length > 0) {
        this.$swal({
          title: "Are you sure?",
          text: "You want to cancel this invoice",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.value) {
            this.ResetInvoice();
            this.$router.go(-1);
          }
        });
      } else {
        this.ResetInvoice();
        this.$router.go(-1);
      }
    },
    async saveInvoice() {
      if (!this.Invoice.CustomerId) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: "Customer not selected!",
          });
        return false;
      }
      if (this.InvoiceDetails) {
        this.loading = true;
        let saveInvoice = this.Invoice;
        // let localInvoiceDetail = []
        // await Promise.all(this.InvoiceDetails.map(detail => {
        //   // console.log('detail', detail)
        //   if (!detail.delete) {
        //     localInvoiceDetail.push(detail)
        //   }
        // }))

        // saveInvoice.InvoiceDetails = localInvoiceDetail // this.InvoiceDetails;
        saveInvoice.InvoiceDetails = this.InvoiceDetails;
        // console.log('localInvoiceDetail', saveInvoice, this.getEditInvoiceId)
        return invoiceService
          .update(this.getEditInvoiceId, saveInvoice)
          .then((updateSave) => {
            // console.log("updateSave", updateSave);
            // productService.resetCache()
            this.ResetInvoice();
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "Invoice was Updated!",
              });
            this.loading = false;
            this.$router.go(-1);
          })
          .catch((err) => {
            // console.log("error on upadting invoice", err);
            this.loading = false;
            this.$swal('Update Invoice', err.data.message, 'error')
            // this.$swal
            //   .mixin({
            //     toast: true,
            //     position: "top-end",
            //     showConfirmButton: false,
            //     timer: 6000,
            //   })
            //   .fire({
            //     icon: "error",
            //     title: err.data.message,
            //   });
          });
      }
    },
    loadData() {
      // console.log('load data')
      const { id } = this.$route.params;
      console.log('getting id', id)
      return invoiceService.getById(id).then((response) => {
        console.log("response edit invoice", response);
        if (response.data) {
          this.initUser = response.data.SalesRep;
          this.initCustomer = response.data.Customer;
          this.initInvoice = response.data;
          this.loadInvoice(response.data);
          this.SetEditInvoiceId(response.data.id);
        }


        return response;
      });
    },
    addRow(val) {
      if (val) {
        this.AddInvoiceDetail({
          ProductId: val.id,
          name: val.Bar.name,
          price: val.Bar.price,
          total: val.Bar.price * val.Bar.qty,
          qty: val.Bar.qty,
          cost: val.cost,
          boxQty: val.Bar.boxQty,
          unitCountInBox: val.unitCountInBox,
          imeiNumber: val.imeiNumber,
        });
      }
    },
  },
};
</script>

<style scoped></style>
