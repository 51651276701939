<template>
  <v-container grid-list-lg fluid>
    <v-layout column>
      <v-flex v-if="order">
        <v-banner outlined :color="color">
          <template v-slot:actions>
            <v-btn text @click="viewOrder" color="info"> View Order </v-btn>
          </template>
          <v-row align="center">
            <v-col>
              <span class="font-weight-medium">Order Number:</span>
              {{ order.localId }}
            </v-col>
            <v-col>
              <span class="font-weight-medium">Total:</span>
              {{ order.total | currency }}
            </v-col>
          </v-row>
        </v-banner>
      </v-flex>
      <v-flex v-if="selectedCustomer && selectedCustomer.alert">
        <v-row id="alert-col">
          <v-col>
            <v-alert v-if="selectedCustomer" border="left" type="error" transition="slide-y-transition" dismissible
              class="mb-0 mt-0" v-model="selectedCustomer.alert" color="red">
              <strong>{{ selectedCustomer.note }}</strong>
            </v-alert></v-col>

          <v-col v-if="
            selectedCustomer &&
            selectedCustomer.note &&
            !selectedCustomer.alert
          ">
            <v-alert v-if="selectedCustomer" transition="slide-y-transition" class="mb-0" dark color="blue">
              <div>
                <span class="body-1">Note:</span><span>{{ selectedCustomer.note }}</span>
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-flex>
      <v-flex>
        <v-card outlined class="mt-0">
          <v-toolbar dense flat :color="$style.purchase.titlebar">
            <v-toolbar-title> Create Invoice </v-toolbar-title>
            <v-spacer> </v-spacer>
            <v-row justify="end">
              <v-col class="shrink">
                <v-btn color="info" small :loading="loadingSaveBtn" :disabled="disabled" @click="saveInvoice">{{
                  invoiceTotal
                  | currency }} Save</v-btn>
              </v-col>

              <v-col class="shrink">
                <v-btn small text @click.stop.prevent="cancel">Cancel</v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
          <SelectCustomer :initCustomer="initCustomer" :selectCustomerError="selectCustomerError"
            @reset="selectCustomerError = []" />
        </v-card>
      </v-flex>
      <v-flex>
        <ProductSearchBar @submit="addRow" @submitMultiple="addMultipleRows" />
      </v-flex>
      <v-flex>
        <Cart />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import invoiceService from "../service";
import customerService from "@/modules/Customer/service";
import ProductSearchBar from "@/modules/Invoice/components/ProductSearchBar";
import orderService from "@/modules/Order/InHouse/service";
import Cart from "@/modules/Invoice/components/Cart";
// import productService from "@/modules/Product/service.js";
import SelectCustomer from "@/modules/Invoice/components/SelectCustomer";
import { mapActions, mapGetters } from "vuex";
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  name: "create-invoice",
  data() {
    return {
      order: null,
      loadingSaveBtn: false,
      loading: true,
      selectCustomerError: [],
      initCustomer: null,
    };
  },
  components: {
    Cart,
    SelectCustomer,
    ProductSearchBar,
  },
  async created() {
    this.SetEditInvoiceId(null);
    if (this.$route.query && this.$route.query.CustomerId) {
      const customer = await customerService.getById(
        this.$route.query.CustomerId
      );
      this.initCustomer = customer.data;
    }
    const obj = this.$route.params;
    if (obj && Object.keys(obj).length !== 0) {
      // // console.log("this.$router", this.$route.params);
      if (obj.type === "purchase") {
        obj.passData.map((val) => {
          this.AddInvoiceDetail({
            ProductId: val.Product.id,
            name: val.Product.name,
            price: val.Product.price,
            total: val.Product.price * val.qty,
            qty: val.qty,
            cost: val.cost | 0,
            imeiNumber: val.imeiNumber,
            previousPrice: val.Product.price,
          });
        });
      }
      if (obj.type === "order") {
        setTimeout(() => {
          this.initCustomer = obj.passData.Customer;
        }, 300);
        this.order = obj.passData;
        if (obj.passData.OrderDetails.length > 0) {
          obj.passData.OrderDetails.map((val) => {
            this.AddInvoiceDetail({
              ProductId: val.Product.id,
              name: val.name,
              price: val.price,
              total: val.price * val.qty,
              qty: val.qty,
              cost: val.Product.avgCost | val.Product.cost | val.cost,
              imeiNumber: val.imeiNumber,
              previousPrice: val.Product.price,
              Product: val.Product,
              needIMEINumber: val.Product.trackSerialNumber ? true : false,
              type: "order",
            });
          });
        }
      }
    }
  },
  computed: {
    ...mapGetters("invoice", [
      "Invoice",
      "InvoiceDetails",
      "invoiceTotal",
      "getSelectedCustomer",
      "getSelectedUser"
    ]),
    ...mapGetters("global", [
      "currentBusiness",
    ]),
    color() {
      let value = "yellow lighten-4";
      if (parseFloat(this.order.total) !== this.invoiceTotal) {
        value = "red lighten-4";
      }

      if (parseFloat(this.order.total) === this.invoiceTotal) {
        value = "green lighten-4";
      }

      return value;
    },
    disabled() {
      let value = true;
      value = this.InvoiceDetails.length === 0;

      this.InvoiceDetails.map((row) => {
        // // console.log('invoice row', row)
        if (row.needIMEINumber && !row.delete) value = true;
      });
      if(!value){
        if (this.currentBusiness.metadata && this.currentBusiness.metadata.lockInvoiceSalesRep && !this.getSelectedUser)
          value = true
      }
      return value;
    },

    selectedCustomer: {
      get: function () {
        return this.getSelectedCustomer;
      },
      set: function (val) {
        this.SetSelectedCustomer(val);
      },
    },
  },
  methods: {
    ...mapActions("invoice", [
      "AddInvoiceDetail",
      "ResetInvoice",
      "SetEditInvoiceId",
      "SetSelectedCustomer",
    ]),
    viewOrder() {
      let order = this.order;
      const hashId = hashids.encode(order.id);
      // // console.log('hashId', hashId)
      const routeData = this.$router.resolve({
        path: `/global/order/${hashId}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    cancel() {
      if (this.InvoiceDetails.length > 0) {
        this.$swal({
          title: "Are you sure?",
          text: "You want to cancel this invoice",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.value) {
            this.ResetInvoice();
            this.$router.go(-1);
          }
        });
      } else {
        this.ResetInvoice();
        this.$router.go(-1);
      }
    },
    saveInvoice() {
      if (this.order) {
        if (parseFloat(this.order.total) !== this.invoiceTotal) {
          this.$swal({
            title: "Are you sure?",
            text: "Order total and invoice total is not matching",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
          }).then((result) => {
            // console.log("result", result);
            if (result.value) {
              this.continueSaving();
            }
          });
        } else {
          this.continueSaving();
        }
      } else {
        // console.log('im herer')
        this.continueSaving();
      }
    },
    continueSaving() {
      if (!this.Invoice.CustomerId) {
        this.selectCustomerError = [];
        this.selectCustomerError.push("Please Select Customer");
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
          })
          .fire({
            icon: "error",
            title: "Customer not selected!",
          });
        return false;
      }
      if (this.InvoiceDetails) {
        var filterInvoiceDetail = this.InvoiceDetails.filter(function (item) {
          if (!item.delete) {
            return item;
          }
        });

        this.loadingSaveBtn = true;
        let saveInvoice = this.Invoice;
        saveInvoice.InvoiceDetails = filterInvoiceDetail;
        const completeDate = new Date();
        console.log('saveInvoice', saveInvoice)
        return invoiceService
          .create(saveInvoice)
          .then(() => {
            if (this.order) {
              orderService.updateStatus(this.order.id, {
                completeDate: completeDate,
              });
            }

            // productService.resetCache();
            this.order = null;
            this.ResetInvoice();
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
              })
              .fire({
                icon: "success",
                title: "Invoice was created!",
              });
            this.loadingSaveBtn = false;
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log("error creating invoice", error);
            this.loadingSaveBtn = false;
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 6000,
              })
              .fire({
                icon: "error",
                title: error.data.message,
              });
          });
      }
    },
    addMultipleRows(products){
      if(!products.length) return true
      products.map(row => {
        let total = 0
        total = row.Bar.price * row.Bar.qty
        const obj = {
          ProductId: row.id,
          name: row.Bar.name,
          price: row.Bar.price,
          total: total,
          qty: row.Bar.qty,
          boxQty: row.Bar.boxQty,
          cost: row.cost | 0,
          unitCountInBox: row.unitCountInBox,
          imeiNumber: row.imeiNumber,
          previousPrice: row.Bar.price,
        }
        this.AddInvoiceDetail(obj);
      })
    },
    addRow(val) {
      console.log('add row', val.unitCountInBox)
      if (val) {
        let total = 0
        // if (val.unitCountInBox && val.unitCountInBox > 0 && this.currentBusiness?.metadata?.boxCountOnInventory) {
        //   total = ((val.Bar.qty * val.unitCountInBox) * val.Bar.price).toFixed(2)
        //   total = parseFloat(total)
        // } else {

        // }this.currentBusiness.metadata.showAvgCostOnProduct
        total = val.Bar.price * val.Bar.qty
        let costAmount = 0
        if (this.currentBusiness ?.metadata ?.showAvgCostOnProduct) {
          costAmount = val.avgCost
        } else {
          costAmount = val.cost
        }
        const obj = {
          ProductId: val.id,
          name: val.Bar.name,
          price: val.Bar.price,
          total: total,
          qty: val.Bar.qty,
          boxQty: val.Bar.boxQty,
          cost: costAmount,
          unitCountInBox: val.unitCountInBox,
          imeiNumber: val.imeiNumber,
          previousPrice: val.Bar.price,
        }
        
        console.log('add row', obj)
        this.AddInvoiceDetail(obj);
      }
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-header__icon {
  margin-top: -10px;
}
</style>
